import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Layout from 'components/Layout';
import Meta from 'components/Meta';

// import { Main } from 'styles/online-feedback-tool';

import FeatureContainer from '../components/FeatureContainer';
import TestimonialsSlider from '../components/TestimonialsSlider/TestimonialsSlider';
import NewFaq from '../components/NewFaq/NewFaq';

import 'styles/online-feedback-tool.scss';
const OnlineFeedbackTool = ({ location }) => {
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState('');

  const onChange = ({ target: { value: v } }) => {
    setValue(v);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const {
        data: { redirectURL },
      } = await axios.post(
        'https://us-central1-ruttlp.cloudfunctions.net/pageReview/website',
        // 'https://us-central1-rally-brucira.cloudfunctions.net/pageReview/website',
        {
          url: value,
        },
      );

      window.location.href = redirectURL;
    } catch (error) {
      /* do nothing */
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout location={location}>
      <Meta
        title="Online Website Feedback Tool 📄 | ruttl "
        description="Get quick feedback on your website with ruttl's online feedback tool. Share your URL, add comments, and use the edit mode feature for seamless collaboration."
        url="https://ruttl.com/online-feedback-tool/"
      />
      <main className="online-feedback-styled-main">
        <section className="feedback-input-section">
          <div className="container">
            <FeatureContainer>
              <div className="feature-set-single">
                <div className="flex flex-wrap align-items-center ">
                  <div className="feature-txt">
                    <h1 className="feature-head">
                      Collect feedback directly on live websites
                    </h1>
                    <p>
                      Add website and share the link with your client to get
                      easy feedback on your website skipping the hassle of
                      screenshots and emails.
                    </p>
                    <form className="feedback-form" onSubmit={onSubmit}>
                      <input
                        type="url"
                        placeholder="Enter your website"
                        className="url-input"
                        onChange={onChange}
                        required
                        value={value}
                      />
                      <button
                        type="submit"
                        className="button"
                        disabled={loading}>
                        Get Link
                      </button>
                    </form>
                    {loading ? (
                      <div style={{ marginTop: 12 }}>
                        Loading, please wait...
                      </div>
                    ) : null}
                  </div>
                  <div className="feature-img">
                    <div className="feature-img-bg">
                      <img
                        src="/assets/img/leave-comments-website.jpg"
                        alt="illustration of leaving comment on live website"
                        title="illustration of leaving comment on live website"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </FeatureContainer>
          </div>
        </section>
        <section className="features">
          <div className="container">
            <h2 className="center">Easy to create and quick to share</h2>
            <div className="flex flex-wrap align-items-stretch center">
              <div className="wrapper">
                <div className="card-single">
                  <div className="card-block">
                    <div className="card-icon">
                      <img
                        loading="lazy"
                        src="/assets/img/multiscreen-testing.svg"
                        alt="multiple windows with tickmark illustration"
                        title="multiple windows with tickmark illustration"
                      />
                    </div>
                    <div className="card-desc">
                      <h3>Add your website</h3>
                      <p>
                        Add the website URL that you want to share with team
                        members to review.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrapper">
                <div className="card-single">
                  <div className="card-block">
                    <div className="card-icon">
                      <img
                        loading="lazy"
                        src="/assets/img/pixel-perfection.svg"
                        alt="four square boxes illustration"
                        title="four square boxes illustration"
                      />
                    </div>
                    <div className="card-desc">
                      <h3>Enter Details</h3>
                      <p>
                        Enter name and email address so we can send you the
                        unique shareable link
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrapper">
                <div className="card-single">
                  <div className="card-block">
                    <div className="card-icon">
                      <img
                        loading="lazy"
                        src="/assets/img/faster-design.svg"
                        alt="pencil hovering over square illustration"
                        title="pencil hovering over square illustration"
                      />
                    </div>
                    <div className="card-desc">
                      <h3>Share the link</h3>
                      <p>
                        Share the link with your team members and collect all
                        the feedback on the same link
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <TestimonialsSlider />
        <NewFaq limitFaq />
      </main>
    </Layout>
  );
};

OnlineFeedbackTool.propTypes = {
  location: PropTypes.object,
};

export default OnlineFeedbackTool;
